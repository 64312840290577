import React, { useState, useEffect } from "react";
import { Grid, Box, Tab, Tabs, Typography, Button } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

export default function CheckExplanation() {
    return  <Grid container direction="column" rowSpacing={2}>
                <Grid item xs={12}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse convallis turpis eget egestas dictum. Sed.
                </Grid>
                <Grid item container direction="row" rowSpacing={2} xs={12}>
                    <Grid item xs={3}>
                        <Typography variant="orange"><WarningAmberIcon fontSize="large"/></Typography>
                    </Grid>
                    <Grid item xs={9}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse convallis turpis eget egestas dictum. Sed.
                    </Grid>
                </Grid>
                <Grid item container direction="row" rowSpacing={2} xs={12}>
                    <Grid item xs={3}>
                        <AddCircleIcon fontSize="large"/>
                    </Grid>
                    <Grid item xs={9}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse convallis turpis eget egestas dictum. Sed.
                    </Grid>
                </Grid>
            </Grid>
}