import React from "react";
import IconDashboard from "../../img/icons/dashboard.png"
import IconMapping from "../../img/icons/mapping.png"
import IconUpload from "../../img/icons/upload.png"
import IconTarget from "../../img/icons/target.png"
import IconChecklist from "../../img/icons/checklist.png"
import IconAdmin from "../../img/icons/admin.png"
import PublishIcon from "../../img/icons/publish.png"

    let numItems = 7
    let menuHeight = (numItems * 120)

    let menuOptions = {
        gridStyle: {backgroundColor : "#3284C6", color: "white", textDecoration: "none", height: "100%"},
        itemStyle: {
            '*:hover': {
                background: "#3078B0",
            }
        },
        boxLogo: {backgroundColor : "#286DA5", height : "auto", minHeight : "75px"},
        boxBottom: {backgroundColor : "#3D3D3D", height : "72%", minHeight : "70px"},
        menuWrapperStyle: {backgroundColor : "primary",width: "100%", height: menuHeight, overflow: "auto"},
        menuStyle: {width: "100%"},
        menuItems: {
            "Dashboard" : {
                "link": "/dashboard",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "submenu": false,
                "title": "Dashboard",
                "icon" : <IconAdmin />,
                "hide" : false,
                "new"  : true,
            },
            "Workspace": {
                "link": "/product-browser",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "title": "Product Browser",
                "submenu": false,
                "icon" : <IconDashboard />,
                "hide" : false,
            },
            "AddProducts": {
                "link": "/add-products",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "submenu": false,
                "title": "Add Products",
                "icon" : <IconUpload />,
                "hide" : false,
            },
            "Mapping": {
                "link": "/mapping",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "submenu": false,
                "title": "Mapping",
                "icon" : <IconMapping />,
                "hide" : false,
            },
            "Reports": {
                "link": "/reports",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "submenu": false,
                "title": "Export preview",
                "icon" : <IconChecklist />,
                "hide" : false,
            },

            "PublishManager": {
                "link": "/publish-manager",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "title": "Publish Manager",
                "submenu": false,
                "icon" : <PublishIcon />,
                "hide" : false,
                "new"  : true,
            },
            "TargetModel" : {
                "link": "/target-models",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "submenu": false,
                "title": "Target Models",
                "icon" : <IconTarget />,
                "hide" : false,
            },
            "Conversions" : {
                "link": "/conversions",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "submenu": false,
                "title": "Conversions",
                "icon" : <IconAdmin />,
                "hide" : false,
                "new"  : true,
            },
            "Profile" : {
                "link": "/profile",
                "userGroups": ["supplier", "retailer", "gs1admin"],
                "submenu": false,
                "title": "Profile",
                "icon" : <IconTarget />,
                "hide" : true,
            },
        },
        menuIcons: {
            'Workspace': <IconDashboard />,
            'Mapping': <IconMapping />,
            'AddProducts': <IconUpload />,
            'Reports': <IconChecklist />,
            'TargetModel': <IconTarget />,
            'Dashboard': <IconTarget />,
            'Profile': <IconTarget />,
            'PublishManager': <IconTarget />,
            'Conversions': <IconTarget />,
        },
        imgStyle: {width: "70%", backgroundColor: "#3D3D3D"},
        textStyle: {color: "white", textDecoration: "none"}
    }


export default menuOptions;