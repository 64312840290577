import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, Button, NativeSelect, Backdrop, CircularProgress } from '@mui/material';
import toolsMappingApi from "../../tools/toolsMappingApi";
import { useNavigate } from "react-router-dom";
import { setGpc } from "../../features/gpcSlice";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PublishManagerViewItems from "./PublishManagerViewItems";
import useToolsNotifications from "../../tools/ToolsNotifications";
import toolsPublishApi from "../../tools/toolsPublishApi";
import PublishGlnSelector from "./PublishGlnSelector";
import PublishIcon from '@mui/icons-material/Publish';
import { minWidth } from "@mui/system";

const dataPoolItems = [
    { label: "GS1 Belgium", key: "GS1-BE"},
    { label: "GS1 Netherlands", key: "GS1-NL"},
    { label: "Trusted Source", key: "TS"},
    
]

export default function PublishManagerItems() {
    const {error, success} = useToolsNotifications();
    const { getDataModel, getMapping, getSourceItems } = toolsMappingApi();
    const {publishGtin} = toolsPublishApi();

    const gpc = useSelector((state) => state.gpc.value);
    const selectedDataModel = useSelector((state) => state.selectedDataModel.value);
    const navigate = useNavigate();
    
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const dispatch = useDispatch();
    const [processedItems, setProcessedItems] = useState(undefined);
    
    const [update, setUpdate] = useState(true);
    const [dataModel, setDataModel] = useState([]);
    const [dataMapping, setMapping] = useState({});
    const [sourceItems, setSourceItems] = useState(undefined);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedGlns, setSelectedGlns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openLoadingOverlay, setOpenLoadingOverlay] = useState(false);
    const [selectedDataPool, setSelectedDataPool] = useState("GS1-BE");

    const header = [{
        field: 'gtin',
        headerName: 'GTIN',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">GTIN</Typography>
        ),
    },{
        field: 'functionalName',
        headerName: 'Product Name',
        minWidth: 270,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">Product Name</Typography>
        ),
    },{
        field: 'gpcCategoryCode',
        headerName: 'GPC Code',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">GPC Code</Typography>
        ),
    },{
        field: 'gpcDescription',
        headerName: 'GPC Desc',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">GPC Desc</Typography>
        ),
    },{
        field: 'status',
        headerName: 'Product Status',
        minWidth: 170,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">Product Status</Typography>
        ),
    },{
        field: 'mapped',
        headerName: 'Mapped',
        minWidth: 120,
        align: 'left',
        renderHeader: () => (
            <Typography variant="tableHeaderLight">Mapped</Typography>
        ),
    },{
        field: 'itemId',
        headerName: 'itemId',
        minWidth: 270,
        align: 'left',
        hide: true,
    }]
   

    useEffect(() =>{
        if(processedItems !== undefined) {
            setLoading(false);
        }
    }, [processedItems])

    useEffect(() => {
        if(selectedDataModel !== undefined && selectedDataModel.classification !== null && dataMapping !== undefined && Object.keys(dataMapping).length > 0) {
            const classificationTargetFieldId = selectedDataModel.classification;
            if(classificationTargetFieldId === undefined || classificationTargetFieldId === null || dataMapping[classificationTargetFieldId] === null || dataMapping[classificationTargetFieldId] === undefined) {
                error('To use the Publish Manager you first need to map "' + classificationTargetFieldId + '" field, as is the key classification field for this data model');
                
            }

            const keyTargetFieldId = selectedDataModel.key;
            if(keyTargetFieldId === undefined || keyTargetFieldId === null || dataMapping[keyTargetFieldId] === null || dataMapping[keyTargetFieldId] === undefined || dataMapping[keyTargetFieldId].value === undefined) {
                error('To use the Publish Manager you first need to map "' + keyTargetFieldId + '" field, as is the key field for this data model');
                //navigate('/mapping')
                //return; 
            }
        }
    }, [dataMapping])


    const showPublishError = (text) => {
        let msg = "Error: " + text;
        error(msg);
    }

    const showPublishSuccess = (n) => {
        let msg = n + " product(s) exported successfully";
        success(msg);
    }

    
    useEffect(() => {
        if(update){
            setLoading(true);
            if(selectedDataModel !== undefined && selectedDataModel.type !== undefined && selectedDataModel.version !== undefined) {
                getDataModel(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                    if(!data.error) {
                        setDataModel(data.payload);
                    }
                });

                getMapping(selectedDataModel.type, selectedDataModel.version, gpc).then((data) => {
                    setMapping(data.payload);
                });

                getSourceItems({gpcCode: gpc}).then((data) => {
                    setSourceItems(data.payload);
                });
            }
            setUpdate(false);
        }
    },[selectedDataModel, gpc, update])

    const processMapping = (mappingData) => {
        let processed = {};

        for(const key in mappingData) {
            if(mappingData[key] !== null) {
                processed[mappingData[key]] = key;
            }
        }
        return mappingData;
    }

    const processItems = (items) => {
        let processed = [];
        let mappingProcessed = processMapping(dataMapping)
        let index = 0;

        //console.log(items)

        items.map((item) => {
            let gpcDescription = "";
            const classificationTargetFieldId = selectedDataModel.classification;
            if(classificationTargetFieldId !== null && classificationTargetFieldId !== undefined && dataMapping[classificationTargetFieldId] !== null && dataMapping[classificationTargetFieldId] !== undefined) {
                const classificationSrcFieldId = dataMapping[classificationTargetFieldId].value;
                const gpc = selectedDataModel.gpcs.find(element => element.brick === item.item[classificationSrcFieldId]);
                gpcDescription = gpc !== undefined ? gpc.brickName !== undefined ? gpc.brickName : '' : '';
            }
            let itemProcessed = {}
            
            for(const i in header) {
                itemProcessed[header[i].field] = '';
            }

            let mapped = 0;
            for(const i in dataModel) {
                if(mappingProcessed[dataModel[i].fieldId] !== undefined) {
                    var fieldId = dataModel[i].fieldId
                    if(typeof fieldId === 'number') {
                        fieldId = fieldId.toString()
                    }
                    itemProcessed[dataModel[i].name] = item.item[mappingProcessed[fieldId].value]
                    mapped++;
                }
            }
            itemProcessed['status'] = item['status']
            
            // if(selectedDataModel.key && mappingProcessed[selectedDataModel.key] && item.item[mappingProcessed[selectedDataModel.key]]) {
            //     itemProcessed['gtin'] = item.item[mappingProcessed[selectedDataModel.key]]
            // } else {
            //     itemProcessed['gtin'] = ''
            // }

            

            itemProcessed['id'] = item['id'];
            itemProcessed['gpcDescription'] = gpcDescription;
            itemProcessed['mapped'] = mapped + '/' + Object.entries(dataModel).length
            index++;

            processed.push(itemProcessed)
        })

        //console.log(processed)
        setProcessedItems(processed)
    }

    useEffect(() => {
        if(sourceItems !== undefined){
            processItems(sourceItems)
        }
    },[sourceItems])

    const handleOnRowClick = (e) => {
        dispatch(setGpc(e.row.gpcCategoryCode));
        let targetUrl = "/mapping";
        navigate(targetUrl);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleDialogPublish = () => {
        if(selectedRows.length > 0) {
            setOpen(true);
        } else {
            showPublishError("Select at least one row to publish.")
        }
    };

    const handlePublish = () => {
        if(selectedGlns.length > 0) {
            setOpenLoadingOverlay(true);
            const payload = {
                dataPool: selectedDataPool,
                receiver: selectedGlns[0],
                autopublish: true,
                sender: "8719333020280",
                dataModel: selectedDataModel.type,
                version: selectedDataModel.version
            }

            if(gpc !== undefined && gpc !== null) {
                payload.gpcCode = gpc;
            }

            let sendStatus = true;
            let failedProds = [];

            selectedRows.map((row) => {
                publishGtin(payload, row).then((data) => {
                    setOpenLoadingOverlay(false);
                    if(data.error) {
                        failedProds.push(row);
                        sendStatus = false;
                        console.log(failedProds);
                        showPublishError("Validation error for: " + failedProds)
                    } else {
                        showPublishSuccess(selectedRows.length);
                    }
                    setOpen(false);
                    setUpdate(true);
                }).catch(error => {
                    setOpenLoadingOverlay(false);
                    showPublishError("Server error")
                })
            })
        } else {
            showPublishError("Select at least one Gln to publish.")
        }
    };

    const descriptionElementRef = React.createRef();

    let mainStyle = {
        marginTop: "20px",
        marginBottom: "20px"
    };
    const buttonStyle = {
        backgroundColor: "#FFF",
        border: "solid 1px #1EB1E0",
        color: "#3284C6",
        fontSize: "12px",
        fontWeight: "400",
        '&:hover': {
            backgroundColor: "rgba(30, 177, 224, 0.1)"
        },
        padding: '5px 30px 5px 16px'
    }

    let inputStyle = { 
        height: "50.24px",
        minWidth: "150px",
        "&:root": {
            fontWeight: "400",
            heigth: "1rem",
            fontSize: "12px",
            lineHeight: "1rem",
        },
    };
    
    return  <Grid item xs={12} height="calc(100% - 112px)" rowSpacing={2}>
                <Grid style={mainStyle} item container direction="row" columnSpacing={2}>
                    <Grid item >
                        <Button 
                            sx={buttonStyle} 
                            onClick={handleDialogPublish}
                            startIcon={<PublishIcon />}
                        >
                            Publish
                        </Button>
                    </Grid> 
                    <Grid item>
                        <NativeSelect
                            id="select-gpc"
                            value={selectedDataPool}
                            label="Data Pool"
                            sx={{maxWidth: "200px", textOverflow: "ellipsis"}}
                            onChange={(e) => {setSelectedDataPool(e.target.value);}}
                        >
                            {dataPoolItems.map((op) => (
                                <option key={op.key} value={op.key}>{op.label}</option>
                            ))}
                        </NativeSelect>
                    </Grid>
                </Grid>
                <PublishManagerViewItems loading={loading} handleOnRowClick={undefined} setSelectionModel={setSelectedRows} mapping={dataMapping} dataModel={dataModel} sourceItems={sourceItems} />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    maxWidth={'lg'}
                >
                    <DialogContent dividers={scroll === 'paper'}>
                        <PublishGlnSelector setSelectedGlns={setSelectedGlns} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handlePublish}>Publish</Button>
                    </DialogActions>
                </Dialog>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => 99999 }}
                    open={openLoadingOverlay}
                >
                    <CircularProgress />
                </Backdrop>
            </Grid>;
}
