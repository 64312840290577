import React, { useState, useEffect } from "react";
import CheckExplanation from "./HelpComponents/CheckExplanation";

const CollapseExplanation = () => {

}

const RenderPreviewExplanation = () => {

}

export const steps = {
    'Dashboard': [
        {
            target: '.totalClass',
            content: 'holis'
        },
        {
            target: '.notClass',
            content: 'holasdasda'
        },
    ],
    'Mapping': [
        {
            disableBeacon: true,
            target: '.mappingFiltersGtin',
            content: 'The GTIN used to preview your data is set here, select any Item you want to see its data in the mapping zone.'
        },
        {
            disableBeacon: true,
            target: '.mappingFiltersStatus',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },        
        {
            disableBeacon: true,
            target: '.datagrid',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
        {
            disableBeacon: true,
            target: '.cellCheckClass',
            content: <CheckExplanation />,
        },
        {
            disableBeacon: true,
            target: '.cellAttributeClass',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
        {
            disableBeacon: true,
            target: '.cellCollapseClass',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
        {
            disableBeacon: true,
            target: '.cellSourceClass',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
        {
            disableBeacon: true,
            target: '.cellMapClass',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
        {
            disableBeacon: true,
            target: '.cellFormulaClass',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
        {
            disableBeacon: true,
            target: '.cellRenderSourceClass',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
        {
            disableBeacon: true,
            target: '.cellRenderPreviewClass',
            content: 'Here you can filter fields by different options, like mapped, mandatory or its type.'
        },
    ],
}






