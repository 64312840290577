import React, { useState, useEffect } from "react";
import toolsPublishApi from "../../tools/toolsPublishApi";
import { alpha, styled } from '@mui/material/styles';
import { Grid, Box, Typography, Button, TextField } from '@mui/material';
import { DataGridPro, GridToolbar, GridToolbarContainer, GridToolbarExport, GridRowModes, gridClasses  } from '@mui/x-data-grid-pro';
import useToolsNotifications from "../../tools/ToolsNotifications";
import toolsRegisterApi from "../../tools/toolsRegisterApi";
import ErrorIcon from '@mui/icons-material/WarningAmberRounded';

let glnStyle = {
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontWeight: '800',
    fontSize: '20px',
    lineHeight: '26px',
    alignItems: 'center',
    color: '#4A4F53',
    width: 'auto'
}

let dateStyle = {
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontSize: '15px',
    alignItems: 'center',
    color: '#4A4F53',
    width: 'auto'
}

let poolStyle = {
    fontFamily: 'Figtree',
    fontStyle: 'normal',
    fontSize: '20px',
    alignItems: 'center',
    color: '#4A4F53',
    width: 'auto'
}

const SyncDataRow = ({data}) => {
    console.log(data)
    return  <Grid container direction="row" xs={4} sx={{height: "auto", padding: "5px", borderTop: "1px solid #4A4F53", borderBottom: "1px solid #4A4F53"}}>
                <Grid item container direction="column" xs={6}>
                    <Grid item>
                        <Typography sx={glnStyle}>{data.gln}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography sx={dateStyle}>{data.syncDate}</Typography>
                    </Grid>
                </Grid>
                <Grid item container xs={6}>
                    <Typography sx={poolStyle}>{data.dataPool}</Typography>
                </Grid>
            </Grid>
}


export default function PublishPanelRow({publishedDataArray, errors, hash}) {
    const [errorData, setErrorData] = useState(['Loading error data...']);
    const { getRegisterStatus } = toolsRegisterApi();

    const header = [
        {
            field: 'gln',
            headerName: 'Gln',
            minWidth: 150,
            align: 'left',
        },
        {
            field: 'syncDate',
            headerName: 'Sync Date',
            minWidth: 150,
            align: 'left',
        },
        {
            field: 'dataPool',
            headerName: 'Data Pool',
            minWidth: 150,
            align: 'left',
        },
    ]
    {/* <Box sx={mainStyle}>
        {publishedDataArray.map((pubTo) => (
            <SyncDataRow data={pubTo} />
        ))}
    </Box> */}
    const mainStyle = {paddingLeft: "100px", height: 500, borderRadius: "4px", marginBottom: '20px'}
    let registerStyle = {color: "#E16C43", width: 80, height: 80}

    useEffect(() => {
        async function getData() {
            if(errors && hash) {
                let st = await getRegisterStatus(hash);
                console.log(st)
                setErrorData(st.payload[0].validationResult);
            }
        }
        getData()
    }, [hash, errors])

    return  publishedDataArray !== undefined ?
                <Grid sx={mainStyle} container>
                    <Grid item xs={12} sm={6}>
                        <DataGridPro
                            disableColumnMenu
                            sx={{fontFamily: "Figtree"}}
                            rows={publishedDataArray}
                            columns={header}
                            getRowId={(r) => r.gln}
                        />
                    </Grid>
                    {errors ?
                    <Grid item xs={12} sm={6}>
                        <Box style={{verticalAlign: 'middle', textAlign: 'center', paddingTop: '20px', paddingLeft: '20px', paddingRight: '10px'}}>
                            <ErrorIcon sx={registerStyle} />
                            {errorData.map((error, index) => 
                                !error.valid ?
                                    <Box style={{textAlign: 'left', marginBottom: '10px'}}><span style={{color: '#AAA'}}>{error.attribute}</span><br/>{error.error}</Box>
                                : ''
                            )}
                        </Box>
                    </Grid> : '' }
                </Grid>
            :
                ''
}