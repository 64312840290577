
import React, { useState, useEffect } from "react";
import { Grid, Typography } from '@mui/material';

export default function HeaderInfo({name, value, total, icon}) {

    const boxStyle = {
        border: "2px solid #DEDEDE",
        borderRadius: "4px",
        paddingTop: "30px",
        paddingBottom: "30px",
        transition: "all .5s",
        "&:hover" : {
            backgroundColor: "#1EB1E033",
            border: "2px solid #3284C6",
            paddingLeft: "20px",
            cursor: "pointer"
        }
    }

    return  <Grid item container direction="row" sx={boxStyle} columnSpacing={2}>
                <Grid item container xs={2} justifyContent={"flex-end"} alignContent={"center"}>
                    <Grid item>
                        {icon}
                    </Grid>
                </Grid>
                <Grid item container direction={"column"} xs={10} alignItems={"flex-start"} >
                    <Grid item>
                        <Typography color={"#666E71"} fontSize={"14px"} fontFamily={"Figtree"} fontWeight={"600"}>
                            {name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color={"#3284C6"} fontSize={"24px"} fontFamily={"Figtree"} fontWeight={"400"}>
                            {total !== undefined ? 
                                value + " / " + total
                            :
                                value
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
}