import React from "react";
import { useState } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText  from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link as RouterLink } from "react-router-dom";
import { List, Grid, Box } from '@mui/material';
import tsLogo from "../../img/tslogo.png";
import bottom from "../../img/menu-bottom.png";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import menuOptions from "./menuOptions.js";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelectedIndex } from "../../features/selectedIndexSlice";

const NewIcon = () => {
    const newIconStyle = {
        background: 'yellow',
        color: '#888',
        padding: '0px 6px',
        borderRadius: '2px',
        fontSize: '10px'
    }
    return <Box sx={newIconStyle}>
        New
    </Box>
}

export default function Menu() {
    const selectedIndex = useSelector((state) => state.selectedIndex.value);
    const dispatch = useDispatch();
    const [open, setOpen] = useState({});

    const handleClick = (key) => {
        setOpen((prevState => ({...prevState, [key]: !prevState[key]})))
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
    ) => {
        dispatch(setSelectedIndex(index));
    };

    return  <Grid container direction="column" wrap={"nowrap"} alignItems="center" alignContent="center" style={menuOptions.gridStyle}>
                <Grid container item justifyContent="center" alignItems="center" style={menuOptions.boxLogo} >
                    <Box width={'60%'} component="img" sx={{ width: 'auto', height: "auto", maxHeight: '25px' }} src={tsLogo}></Box>
                </Grid>
                <Grid container direction="column" style={menuOptions.menuWrapperStyle}>
                    <List style={menuOptions.menuStyle}>
                        {Object.entries(menuOptions.menuItems).map(([key, value]) => (
                            !value.hide ?
                                typeof value === 'object' && value.submenu && typeof value.submenuLinks === 'object' ?
                                    <List style={menuOptions.menuStyle}>
                                        <ListItem key={key} className="list-item" style={menuOptions.itemStyle}>
                                            <ListItemButton onClick={() => handleClick(key)}>
                                                <ListItemIcon sx={{ color: "white" }}> 
                                                    {value.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={key} />
                                                {open[key] ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={open[key]} timeout="auto" unmountOnExit>
                                            {Object.entries(menuOptions.menuItems[key]["submenuLinks"]).map(([reportKey, reportValue]) => (
                                                reportValue.isExternalLink ?
                                                <ListItem key={reportKey} className="list-item"  component="a" href={reportValue.link} style={menuOptions.itemStyle} target="_blank">
                                                <ListItemButton className="list-button" sx={{ pl: 8 }} selected={selectedIndex === reportKey} onClick={(event) => handleListItemClick(event, reportKey)}>
                                                    <ListItemText style={menuOptions.textStyle} primary={reportKey} />
                                                </ListItemButton>
                                                </ListItem>
                                                :
                                                <ListItem className="list-item"  component={RouterLink} to={reportValue.link} style={menuOptions.itemStyle} >
                                                <ListItemButton className="list-button" sx={{ pl: 8 }} selected={selectedIndex === reportKey} onClick={(event) => handleListItemClick(event, reportKey)}>
                                                    <ListItemText style={menuOptions.textStyle} primary={reportKey} />
                                                </ListItemButton>
                                                </ListItem>
                                            ))}
                                        </Collapse>
                                    </List>
                                    :
                                    <ListItem key={key} className="list-item"  component={RouterLink} to={value.link} style={menuOptions.itemStyle}>
                                        <ListItemButton className="list-button" selected={selectedIndex === key} onClick={(event) => handleListItemClick(event, key)}>
                                            <ListItemIcon>
                                                <img src={value.icon.type} />
                                            </ListItemIcon>
                                            <ListItemText style={menuOptions.textStyle} primary={value.title} />
                                            {value.new ? <NewIcon /> : ''}
                                        </ListItemButton>
                                    </ListItem>
                            :
                            ''
                        ))}
                    </List>
                </Grid>
                <Grid container item justifyContent="center" alignItems="center" style={menuOptions.boxBottom} >
                    <Box style={menuOptions.imgStyle}></Box>
                </Grid>
            </Grid>
}