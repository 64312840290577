import { useState } from "react";

export default function useCollapsable() {
    const [collapseGroups, setCollapseGroups] = useState({});
    const [collapsedItems, setCollapsedItems] = useState([]);

    const removeElementsCollapsed = (elements) => {
        let oldItems = collapsedItems
        console.log(elements);
        console.log(oldItems);
        let newItems = oldItems.filter(function(item) {
            return !elements.includes(item);
        })
        console.log(newItems)
        setCollapsedItems(newItems);
    }

    const addElementsCollapsed = (elements) => {
        let oldItems = collapsedItems
        const concatArr = oldItems.concat(elements)
        const newItems = concatArr.filter((item, idx) => concatArr.indexOf(item) === idx)
        setCollapsedItems(newItems);
    }

    const handleCollapseChange = (row, items) => {
        let collapse = {...collapseGroups};
        let newValue = !collapse[row.fieldId];
        collapse[row.fieldId] = newValue;
        setCollapseGroups(collapse);

        let group = [];

        if(row.group !== undefined && row.group !== null && row.group === row.fieldId && row.child !== true) {
            group = items.filter((elem) => { return elem.originalGroup === row.fieldId});
            group = group.map(elemRes => elemRes.fieldId);
        } else {
            if(row.multivalue) {
                group = items.filter((elem) => { return elem.originalId === row.fieldId});
                group = group.map(elemRes => elemRes.fieldId);
            } else {
                if(row.multilingual) {
                    group = items.filter((elem) => { return elem.originalId === row.fieldId});
                    group = group.map(elemRes => elemRes.fieldId);
                }
            }
        }

        console.log(group)
        if(group.length > 0) {
            if(newValue) {
                removeElementsCollapsed(group);
            } else {
                addElementsCollapsed(group);
            }  
        }
        
        
    }

    return {
        collapseGroups,
        setCollapseGroups,
        collapsedItems,
        setCollapsedItems,
        handleCollapseChange,
    };
}